// another function to go from string to ByteArray, but we first encode the

import { IRegisteredCredentials } from '../type';

// string as base64 - note the use of the atob() function
export const strToBin = (str: string) => {
	return Uint8Array.from(atob(str), c => c.charCodeAt(0));
};

// function to encode raw binary to string, which is subsequently
// encoded to base64 - note the use of the btoa() function

export const binToStr = (bin: any) => {
	return btoa(
		new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), '')
	);
};

export const getRegistrations = () => {
	const registrations = JSON.parse(
		localStorage.onboardedAIRegistrations || '[]'
	);
	if (registrations?.length > 20) {
		localStorage.setItem('onboardedAIRegistrations', '[]');
		return [];
	}
	return registrations;
};

export const setRegistrations = (registrations: IRegisteredCredentials) => {
	localStorage.onboardedAIRegistrations = JSON.stringify(
		registrations,
		null,
		'  '
	);
};

export const saveNewCredentials = (registration: IRegisteredCredentials) => {
	const registrations = getRegistrations();
	registrations.push(registration);
	setRegistrations(registrations);
};

export const removeDigits = (number: number | string, digits: number) => {
	if (!number) {
		return number;
	}
	return number?.toString()?.slice(0, digits);
};

export const appendElementToHead = (
	tagName: keyof HTMLElementTagNameMap,
	attributes: Record<string, string>,
	configureElement?: (element: HTMLElement) => void
) => {
	const head: any = document.getElementsByTagName('head')[0];
	const element = document.createElement(tagName);

	// Apply attributes
	Object.entries(attributes).forEach(([key, value]) => {
		element.setAttribute(key, value);
	});

	// Allow additional configuration by the caller
	if (configureElement) {
		configureElement(element);
	}

	head.appendChild(element);
};
