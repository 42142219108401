export const forceCallAPIForIFrame506b = [
	'corporationPartnership',
	'privateBusinessDevelopment',
	'underSignedTrust',
	'selfDirectedEmployee',
	'netWorthValidation',
	'incomeValidation',
	'finraUploadDocuments',
	'ownerOfCompany',
	'noneOfAboveUploadDocuments',
	'entityCommonUpload',
];

export const EntityAvoidOptions = {
	DescribeEntity: 'describeEntity',
};

export const finraLisenceDropValue = [
	{
		value: 'Licensed General Securities Representative (Series 7)',
		label: 'Licensed General Securities Representative (Series 7)',
	},
	{
		value: 'Licensed Investment Adviser Representative (Series 65)',
		label: 'Licensed Investment Adviser Representative (Series 65)',
	},
	{
		value: 'Licensed Private Securities Offerings Representative (Series 82)',
		label: 'Licensed Private Securities Offerings Representative (Series 82)',
	},
];
export const finraLisenceDropDownDefaultValue = {
	value: 'Licensed General Securities Representative (Series 7)',
	label: 'Licensed General Securities Representative (Series 7)',
};

export const getSignatoryTypeForm = (back: string) => {
	return {
		type: 'radio-select',
		label: 'Signatory type',
		title:
			'Choose the profession of the signatory who will sign the Accreditation Letter',
		back,
		subTitle: ' ',
		settings: [
			{
				id: 1,
				type: 'radio',
				key: 'certifiedPublicAccountant',
				label: 'Certified Public Accountant',
			},
			{
				id: 2,
				type: 'radio',
				key: 'licensedAttorney',
				label: 'Licensed Attorney',
			},
			{
				id: 3,
				type: 'radio',
				key: 'registeredBrokerDealer',
				label: 'Registered Broker Dealer',
			},
			{
				id: 4,
				type: 'radio',
				key: 'registeredInvestmentAdvisor',
				label: 'Registered Investment Advisor',
			},
		],
	};
};

export const MLC_LABEL =
	'Send an e-sign document to your attorney, CPA, broker-dealer, or investment advisor to verify your accreditation status.';

export const restrictedForNonOfAbow = [
	{ name: 'morning line club', url: 'https://www.morninglineclub.com/' },
];

export const restrictedKeys: { [key: string]: boolean } = {
	noneOfAboveUploadDocuments: true,
	entityNonOfAbowUpload: true,
};

export const accreditationTypes = {
	'506b': '506b',
	'506c': '506c',
};

export const nonOfAboveLabel = 'None of the above apply to me';

export const businessIdToHideNodeOption = [
	'66ceebd2b4cc63728593b8af', // Liquidity prod ID
	'65dc30fda548f97319a01655', // Arun kumar stage ID
	'65703cd48044be4eea934e4c', //  Liquidity beta ID
];
