import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useTokenSession,
} from 'hooks';
import { AccessTokenState, isShowSkipState } from 'states';
import { APIS, API_TYPE } from 'constants/api';
import { getDateObj } from 'utils';
import {
	BuisnessCompanyListState,
	BusinessInformationState,
	IAddress,
	IsKybSupplimentalTrue,
	IsShowOwnerShipOptionKybMemberListState,
	KybBodyStepsState,
	KYBCompanyFlowState,
	MultipleRepresentativeDetailsState,
	RepresentativeDataThroughKyc,
	RepresentativeFilledToggleState,
	SearchKybCompanyInputState,
	SearchKybCompanyListState,
	SearchKybCompanyMembersInputState,
	SearchKybCompanyMembersListState,
	SelectedCompanyForKybState,
	SelectedSubsidiaryBusinessDetailState,
	SubsidiaryBusinessListState,
	SubsidiaryBusinessLoadingState,
	TheKYBCompanyMemberForKycAmlVerification,
	TheKYBCompanyMemberForKycAmlVerificationListState,
	TheKYBCompanyMemberListState,
	TheKybSubmitLoadingState,
} from './states';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import {
	BUSINESS_INFO_CONSTATNTS,
	customMember,
	SUBSIDIARY_BUSINESS_CONSTANTS,
	THE_KYB_PROVIDER_TOKEN,
} from '../constants';
import { getCountry } from 'utils/get-country';
import { EVENTS_TRACKING, timestamp, useTrackEvents } from 'helpers';

export const useKYBRequests = () => {
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const selectedCompanyForKyb = useRecoilValue(SelectedCompanyForKybState);
	const setTheKybSubmitLoading = useSetRecoilState(TheKybSubmitLoadingState);
	const kYBCompanyFlow = useRecoilValue(KYBCompanyFlowState);

	const isKybSupplimental = useRecoilValue(IsKybSupplimentalTrue);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const setRepresentativeDetails = useSetRecoilState(
		MultipleRepresentativeDetailsState
	);
	const { apiEndPoint, envHost } = useSharedVariables();
	const [representativeThroughKyc, setRepresentativeThroughKyc] =
		useRecoilState(RepresentativeDataThroughKyc);
	const [isToggleOn, setIsToggleOn] = useRecoilState(
		RepresentativeFilledToggleState
	);

	const [selectedMemberDetails, setSelectedMemberDetails] = useRecoilState(
		TheKYBCompanyMemberListState
	);

	const setSubsidiaryBusinessList = useSetRecoilState(
		SubsidiaryBusinessListState
	);
	const setSubsidiaryBusinessLoading = useSetRecoilState(
		SubsidiaryBusinessLoadingState
	);
	const getSelectSubsidiaryBusiness = useRecoilValue(
		SelectedSubsidiaryBusinessDetailState
	);
	const isShowOwnerShipOption = useRecoilValue(
		IsShowOwnerShipOptionKybMemberListState
	);
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const businessInformation = useRecoilValue(BusinessInformationState);
	const { nodes } = sessionDetails ?? {};
	const { pipelineActions } = nodes ?? {};
	const { metadata } = pipelineActions?.[0] ?? [];
	const {
		representative_kyc_aml_verification: isKycAmlVerfication,
		kybSubsidiary: isKybForSubsidiary,
	} = metadata ?? {};

	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();

	const { postSession: kybSubsidiary } = useTokenSession();
	const { apiEndPoint: API_HOST } = useSharedVariables();
	const { trackEvents } = useTrackEvents();

	// Arun kumar : getting stage condition based on env
	const IsStage = useMemo(() => {
		if (/stage/gi.test(apiEndPoint)) {
			return true;
		} else {
			return false;
		}
	}, [apiEndPoint]);

	const { sessionPayloadDetail, handleNext, setSessionDetails } = useNextStep();

	const { successNotification, errorNotification, infoNotification } =
		useNotification();

	const getSearchKybCompanyMemberInput = useRecoilValue(
		SearchKybCompanyMembersInputState
	);

	const { stepsId, _id, userId, currentAction, sessionId } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const buisnessCompanyListStateValue = useRecoilValue(
		BuisnessCompanyListState
	);

	const KycAmlSelectedMembers = useRecoilValue(
		TheKYBCompanyMemberForKycAmlVerificationListState
	);

	const getSelectedKycAmlMembers = useRecoilValue(
		TheKYBCompanyMemberForKycAmlVerification
	);
	const kybBodyStep = useRecoilValue(KybBodyStepsState);

	const setSearchCompanyMemberList = useSetRecoilState(
		SearchKybCompanyListState
	);

	const setSearchKybCompanyInput = useSetRecoilState(
		SearchKybCompanyInputState
	);

	const setSearchKybCompanyMembersList = useSetRecoilState(
		SearchKybCompanyMembersListState
	);

	const setSearchKybCompanyMemberInput = useSetRecoilState(
		SearchKybCompanyMembersInputState
	);

	// Generates 'US' formatted address string from address object
	const getAddress = useCallback((address: IAddress) => {
		if (!Object.keys(address).length) {
			return 'N/A';
		}
		const {
			StreetNumber = '',
			StreetName = '',
			StreetSuffix = '',
			StreetPostDirection = '',
			City = '',
			State = '',
			Zip5 = '',
			Zip4 = '',
			County = '',
		} = address || {};
		return `${StreetNumber} ${StreetName} ${StreetSuffix} ${StreetPostDirection} ${City},${State} ${Zip5}-${Zip4} ${County}`;
	}, []);

	const isSelectSubsidiaryBusiness = useMemo(
		() =>
			getSelectSubsidiaryBusiness &&
			isKybForSubsidiary &&
			businessInformation?.country === BUSINESS_INFO_CONSTATNTS.IsUSCountry,

		[
			businessInformation?.country,
			getSelectSubsidiaryBusiness,
			isKybForSubsidiary,
		]
	);

	const handlingKycAmlSelectedMembersData = useCallback(() => {
		const term = getSearchKybCompanyMemberInput;

		const searchFilteredData = KycAmlSelectedMembers.members.filter(
			member =>
				getSelectedKycAmlMembers.id.includes(member.id) ||
				(member.designation?.toLowerCase()?.includes(term) ?? false) ||
				(member.name?.toLowerCase()?.includes(term) ?? false)
		);
		// Check if customMember is already in the filtered data
		const customMemberExists = searchFilteredData.some(
			member => member.id === customMember.id
		);

		const getMembersList =
			searchFilteredData.length > 0 || customMemberExists
				? [...searchFilteredData, ...(customMemberExists ? [] : [customMember])]
				: [customMember];

		setSearchKybCompanyMembersList((prevState: any) => ({
			...prevState,
			members: getMembersList,
		}));
	}, [
		KycAmlSelectedMembers.members,
		getSearchKybCompanyMemberInput,
		getSelectedKycAmlMembers.id,
		setSearchKybCompanyMembersList,
	]);

	useEffect(() => {
		if (
			kybBodyStep === 'select_member' &&
			getSearchKybCompanyMemberInput.length
		) {
			handlingKycAmlSelectedMembersData();
		}
	}, [
		getSearchKybCompanyMemberInput,
		handlingKycAmlSelectedMembersData,
		kybBodyStep,
	]);

	const submitKyb = useCallback(
		async (
			formData: any,
			representativeDetails?: any,
			IsTheKybSupported?: boolean
		) => {
			setTheKybSubmitLoading(true);
			const {
				linkedInUrl,
				national_id_number,
				name,
				website,
				fein,
				date_of_birth,
				streetAddress,
				city,
				state,
				postal_code,
				phone,
				person,
				tinType,
				country,
			} = formData ?? {};
			const { activeIndex, members } = selectedMemberDetails;
			const cloneMembers = JSON.parse(JSON.stringify(members));
			let payloadMember: any[] = [];
			if (activeIndex !== null && !isKycAmlVerfication) {
				if (cloneMembers[activeIndex]?.id === 'custom') {
					delete (cloneMembers as any)?.[activeIndex]?.id;
					delete (cloneMembers as any)?.[activeIndex]?.name;
					delete (cloneMembers as any)?.[activeIndex]?.status;
					delete (cloneMembers as any)?.[activeIndex]?.designation;
					payloadMember = [cloneMembers[activeIndex]];
				} else {
					if (isShowOwnerShipOption) {
						payloadMember = [
							{
								id: cloneMembers[activeIndex]?.id,
								type: cloneMembers[activeIndex]?.type,
								...(cloneMembers[activeIndex]?.type !== 'signer' && {
									ownershipPercentage:
										cloneMembers[activeIndex]?.ownershipPercentage,
								}),
							},
						];
					} else {
						payloadMember = [{ id: cloneMembers[activeIndex]?.id }];
					}
				}
				// paras: only in case of PSB
				if (isShowOwnerShipOption) {
					payloadMember = payloadMember.map(member => ({
						...member,
						type: member.type?.value,
						...(member.type?.value !== 'signer' && {
							ownershipPercentage: parseFloat(member.ownershipPercentage),
						}),
					}));
				}
			}
			payloadMember = payloadMember.map((member: any) => {
				if (member?.national_id_number) {
					const ssnDigits = member?.national_id_number?.replace(
						/[^0-9a-zA-Z]/g,
						''
					);
					return {
						...member,
						national_id_number: ssnDigits,
					};
				}
				return {
					...member,
				};
			});
			payloadMember = payloadMember.map(member => {
				if (member.type === 'signer') {
					const updatedMember = { ...member };
					delete updatedMember.ownershipPercentage; // Remove ownershipPercentage
					return updatedMember;
				}
				return member;
			});

			// paras: here we are handling payload of kyc-aml selected members
			if (isKycAmlVerfication) {
				let kycAmlMembersPayload: any[] = [];
				const { members } = KycAmlSelectedMembers;
				const checkKyc = {
					isFreshKycLink: true,
					doKyc: true,
				};

				// removing countrycode form 'phone' key
				const transformPhoneKey = (member: any) => {
					const { phone, country_code, ...rest } = member;
					if (phone && country_code) {
						const modifiedPhone = phone.replace(country_code, '');
						return { ...rest, phone: modifiedPhone, country_code };
					}
					return member;
				};

				// kyc-aml selected members
				const getKycAmlSelectedMembers = members
					.filter(
						member =>
							getSelectedKycAmlMembers?.id.includes(member?.id) &&
							member.id !== 'custom'
					)
					.map(member => {
						// Send checkKyc if isShowOwnerShipOption is false or if ownershipPercentage > 10
						if (
							isShowOwnerShipOption &&
							(parseFloat(member.ownershipPercentage) < 10 ||
								member.type.value === 'signer')
						) {
							return member;
						}

						return {
							...member,
							checkKyc,
						};
					});

				kycAmlMembersPayload = [
					...kycAmlMembersPayload,
					...getKycAmlSelectedMembers,
				];

				const customMember = members.find(item => item.id === 'custom');
				if (customMember?.email) {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { id, name, designation, ...modifiedCustomMember } =
						customMember;
					if (getSelectedKycAmlMembers?.id.includes('custom')) {
						modifiedCustomMember.checkKyc = checkKyc;
					}
					kycAmlMembersPayload.push(modifiedCustomMember);
				}
				// paras: only in case of PSB adding ownership values in payload
				if (isShowOwnerShipOption) {
					kycAmlMembersPayload = kycAmlMembersPayload.map(member => {
						const { type, ownershipPercentage, ...rest } = member;
						return {
							...rest,
							type: type?.value,
							...(type?.value !== 'signer' && {
								ownershipPercentage: parseFloat(ownershipPercentage),
							}),
						};
					});
				}

				// final kyc-aml selected members payload
				payloadMember = kycAmlMembersPayload?.map(transformPhoneKey);
			}

			/* @paras: subsidiary business payload */
			const subsidiaryBusinessPayload = [];
			if (isSelectSubsidiaryBusiness) {
				infoNotification(SUBSIDIARY_BUSINESS_CONSTANTS.KYB_Process_Message);
				for (const item of getSelectSubsidiaryBusiness || []) {
					const country = await getCountry((item?.Address as string) || '');
					subsidiaryBusinessPayload.push({
						companyName: item?.name || '',
						businessIds: item?.extra?.BusinessIds || {},
						tin: item?.TIN || '',
						country: country,
						address: item?.Address || '',
						id: item?.id || '',
					});
				}
				trackEvents(EVENTS_TRACKING.KYB_COMPLETED, {
					timestamp: timestamp,
				});
			}

			const payload: any = {
				pipelineId: _id ?? '',
				start: true,
				userId: userId ?? '',
				// Arun kumar : type added for Kyb supplimental case if supplimental is true then we are sending supplemntal
				type: isKybSupplimental
					? 'supplemental'
					: IsStage
						? 'standard'
						: 'supplemental',
				actions: [
					{
						id: 'kybVerification',
						data: {
							name: name,
							website: website,
							fein: fein,
							tinType,
							linkedInUrl: linkedInUrl,
							date_of_birth,
							national_id_number,
							address: {
								street_address1: streetAddress,
								street_address2: null,
								city: city,
								state: state,
								postal_code: postal_code,
								type: null,
								phone: phone,
							},
							person: person,
						},
					},
				],
			};

			//Gaurav: Complex multiple representative API payload.
			const complexPayload: any = {
				...(isKycAmlVerfication && { sessionId: sessionId ?? '' }),
				pipelineId: _id ?? '',
				start: true,
				userId: userId ?? '',
				nodeId: currentAction._id ?? '',
				flow: kYBCompanyFlow,
				...(IsTheKybSupported
					? { request_id: selectedCompanyForKyb?.requestId }
					: {}),

				// Arun kumar : type added for Kyb supplimental case if supplimental is true then we are sending supplemntal
				type: isKybSupplimental
					? 'supplemental'
					: IsStage
						? 'standard'
						: 'supplemental',
				actions: [
					{
						id: 'kybVerification',
						data: {
							name: name,
							website: website,
							fein: fein,
							tinType,
							linkedInUrl: linkedInUrl,
							address: {
								street_address1: streetAddress,
								street_address2: null,
								city: city,
								state: state,
								postal_code: postal_code,
								type: null,
								phone: phone,
								...(kYBCompanyFlow === 'manual' ? { country: country } : {}),
							},
							representatives: representativeDetails.length
								? representativeDetails
								: payloadMember,
							subsidiaries: subsidiaryBusinessPayload,
						},
						flow: kYBCompanyFlow,
						...(IsTheKybSupported
							? {
									company_id: selectedCompanyForKyb?.id,
									request_id: buisnessCompanyListStateValue?._id,
								}
							: {}),
					},
				],
			};
			if (onboardingType === 'complex') {
				// COMPLEX_SESSION patch api
				const res = await postTokenSession({
					payload: complexPayload,
					code: sessionCode,
				});

				if (res.statusCode === 200) {
					successNotification('KYB Submitted Successfully ');
					setTheKybSubmitLoading(false);
					const kybResponse = { ...res };
					delete kybResponse.statusCode;
					setSessionDetails(prev => ({
						...prev,
						nodes: kybResponse,
					}));
				} else {
					errorNotification(res?.message ?? 'Something Went Wrong ');
					setTheKybSubmitLoading(false);
					setIsShowSkip(true);
				}
				trackEvents(EVENTS_TRACKING.KYB_COMPLETED, {
					timestamp: timestamp,
				});
				return;
			}
			payload.stepId = stepsId;
			// KYB_SUBMIT patch api
			const res = await postTokenSession({ payload, code: sessionCode });

			if (res.statusCode === 200) {
				setTheKybSubmitLoading(false);
				successNotification('KYB Submitted Successfully ');
				trackEvents(EVENTS_TRACKING.KYB_COMPLETED, {
					timestamp: timestamp,
				});
				handleNext();
			} else {
				errorNotification('Something Went Wrong ');
				setTheKybSubmitLoading(false);
				setIsShowSkip(true);
			}
		},
		[
			setTheKybSubmitLoading,
			selectedMemberDetails,
			_id,
			userId,
			isKybSupplimental,
			IsStage,
			currentAction._id,
			kYBCompanyFlow,
			selectedCompanyForKyb?.requestId,
			selectedCompanyForKyb?.id,
			buisnessCompanyListStateValue?._id,
			onboardingType,
			stepsId,
			postTokenSession,
			sessionCode,
			successNotification,
			setSessionDetails,
			errorNotification,
			setIsShowSkip,
			handleNext,
			sessionId,
			isKycAmlVerfication,
			getSelectedKycAmlMembers,
			KycAmlSelectedMembers,
			infoNotification,
			getSelectSubsidiaryBusiness,
			isSelectSubsidiaryBusiness,
			trackEvents,
			isShowOwnerShipOption,
		]
	);

	const getKycDataForRepresentative = useCallback(async () => {
		const resp = await postTokenSession({
			type: API_TYPE.PREFILL_REPRESENTATIVE_DETAILS,
			code: sessionCode,
		});
		const {
			statusCode,
			firstName,
			lastName,
			phone,
			ssn,
			countryCode,
			dateOfBirth,
		} = resp ?? {};
		if (statusCode === 200) {
			setRepresentativeThroughKyc({
				first_name: firstName,
				last_name: lastName,
				telephone_number: phone,
				national_id_number: ssn,
				country_code: countryCode,
				date_of_birth: dateOfBirth && getDateObj(dateOfBirth),
			});
		}
	}, [postTokenSession, sessionCode, setRepresentativeThroughKyc]);

	const changeRepresentativeToggle = useCallback(
		(value: boolean, callBack?: () => void) => {
			setIsToggleOn(value);
			callBack?.();
			if (value) {
				setRepresentativeDetails(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					const { telephone_number, country_code } =
						representativeThroughKyc ?? {};
					prevState[0] = {
						...representativeThroughKyc,
						telephone_number: country_code + telephone_number,
					};
					return prevState;
				});
			} else {
				setRepresentativeDetails(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					prevState[0] = {
						first_name: '',
						last_name: '',
						telephone_number: '',
						date_of_birth: '',
						country_code: '',
						national_id_number: '',
					};
					return prevState;
				});
			}
		},
		[representativeThroughKyc, setIsToggleOn, setRepresentativeDetails]
	);

	const showPrefilledToggle = () => {
		const { first_name, last_name, date_of_birth, national_id_number } =
			representativeThroughKyc ?? {};
		return !!(first_name || last_name || date_of_birth || national_id_number);
	};

	const isInputDisabled = (name: string, index: number) => {
		if (index !== 0) return false;
		if (isToggleOn && representativeThroughKyc[name as 'first_name']) {
			if (name === 'telephone_number') {
				return !!(
					representativeThroughKyc.telephone_number &&
					representativeThroughKyc.country_code
				);
			} else {
				return true;
			}
		}
		return false;
	};

	const changeSelectedMemberToggle = useCallback(
		(value: boolean, index: number, callBack?: () => void) => {
			setIsToggleOn(value);
			callBack?.();
			if (value) {
				setSelectedMemberDetails(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					const { telephone_number, country_code } =
						representativeThroughKyc ?? {};
					prevState.members[index] = {
						...prevState.members[index],
						...representativeThroughKyc,
						telephone_number: country_code + telephone_number,
						phone: country_code + telephone_number,
					};

					return prevState;
				});
			} else {
				setSelectedMemberDetails(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					prevState.members[index] = {
						...prevState.members[index],
						first_name: '',
						last_name: '',
						telephone_number: '',
						date_of_birth: '',
						country_code: '',
						national_id_number: '',
					};
					return prevState;
				});
			}
		},
		[representativeThroughKyc, setIsToggleOn, setSelectedMemberDetails]
	);

	const isSameMemberAsKYC = useCallback(
		(name: string) => {
			const { first_name, last_name } = representativeThroughKyc ?? {};

			return name?.toUpperCase() === `${first_name} ${last_name}`;
		},
		[representativeThroughKyc]
	);

	const getSubsidiaryBusiness = useCallback(async () => {
		setSubsidiaryBusinessLoading(true);
		const getkybConfiguration = metadata?.Kyb ? metadata : {};
		const payload = {
			session: sessionDetails.sessionId || '',
			companyId: selectedCompanyForKyb?.id || '',
			kybConfiguration: getkybConfiguration,
		};

		// POST api call for kyb Subsidiary Business
		const res = await kybSubsidiary({
			url: `${APIS.KYB_SUBSIDIARY_BUSINESS}`,
			payload,
			apiHost: API_HOST,
			customConfig: {
				headers: {
					Authorization: `Bearer ${THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]}`,
					'Content-Type': 'application/json',
				},
			},
		});
		const { data } = res || {};
		if (data) {
			setSubsidiaryBusinessLoading(false);
			setSubsidiaryBusinessList(data || []);
		} else {
			setSubsidiaryBusinessLoading(false);
		}
	}, [
		API_HOST,
		envHost,
		kybSubsidiary,
		metadata,
		selectedCompanyForKyb?.id,
		sessionDetails.sessionId,
		setSubsidiaryBusinessList,
		setSubsidiaryBusinessLoading,
	]);

	// this funtion is only for search input of kyb header
	const handleSearchInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const term = event.target.value.toLowerCase();
			if (kybBodyStep === 'select_member') {
				setSearchKybCompanyMemberInput(term);
				handlingKycAmlSelectedMembersData();
			}

			if (kybBodyStep === 'select_company') {
				setSearchKybCompanyInput(term);
				const searchFilteredData =
					buisnessCompanyListStateValue?.companies?.filter(
						(company: any) =>
							(company.tin?.includes(term) ?? false) ||
							(company.name?.toLowerCase()?.includes(term) ?? false)
					);
				setSearchCompanyMemberList((prevState: any) => ({
					...prevState,
					companies: searchFilteredData,
				}));
			}
		},
		[
			buisnessCompanyListStateValue?.companies,
			handlingKycAmlSelectedMembersData,
			kybBodyStep,
			setSearchCompanyMemberList,
			setSearchKybCompanyInput,
			setSearchKybCompanyMemberInput,
		]
	);

	return {
		submitKyb,
		getKycDataForRepresentative,
		changeRepresentativeToggle,
		showPrefilledToggle,
		isInputDisabled,
		changeSelectedMemberToggle,
		isSameMemberAsKYC,
		getSubsidiaryBusiness,
		getAddress,
		handleSearchInputChange,
	};
};
