import {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Button, Input, Loader } from '@storybook';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BodyWrapper, LabelElement } from 'components';
import { SsnNumberState, useKycRequests } from 'views/kyc/stores';

import './provide-ssn.scss';
import { CountryStateListState } from 'views/kyb/stores';
import { KycSelectedCountry } from '../../store';
import { pointGen } from 'utils/format-text';

export const ProvideSSNNumber = () => {
	const [nationalIdNumber, setNationalIdNumber] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [ssnNumber, setSsnNumber] = useRecoilState(SsnNumberState);
	const setCountryListResp = useSetRecoilState(CountryStateListState);
	const selectedCountry = useRecoilValue(KycSelectedCountry);
	const [error, setError] = useState({
		national_id_number: '',
	});

	const isUsa = /USA/gi.test(selectedCountry.iso3);
	// State to hold the masked version of the SSN e.g., '********')
	const [maskedSsn, setMaskedSsn] = useState<string>('');

	// State to hold the unmasked version of the SSN (e.g., '123456789')
	const [unmaskedSsn, setUnmaskedSsn] = useState<string>('');

	// State to determine whether the SSN should be masked or unmasked
	const [isMasked, setIsMasked] = useState<boolean>(true);

	// Reference to the input field to manage and restore the cursor position
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		// Start a timer to debounce the masking logic by 800ms
		const timer = window.setTimeout(() => {
			// Ensure the inputRef is attached to a valid input element
			if (inputRef.current) {
				// Save the current cursor position to avoid disrupting user typing
				const cursorPos = (inputRef.current as HTMLInputElement).selectionStart;

				// Update the masked value of the national ID
				// pointGen generates the masked version (e.g., "••••••••")
				setMaskedSsn(pointGen(maskedSsn?.length || 0));

				// Restore the cursor position after masking the input
				(inputRef.current as HTMLInputElement).selectionStart = cursorPos;
				(inputRef.current as HTMLInputElement).selectionEnd = cursorPos;
			}
		}, 800); // 800ms delay for debouncing

		// Cleanup function to clear the timer if dependencies change or the component unmounts
		return () => window.clearTimeout(timer);
	}, [maskedSsn]); // Runs only when maskedSsn

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			let maxDigit = 15;
			if (selectedCountry?.iso3 === 'USA') {
				maxDigit = 9;
			} else if (selectedCountry?.iso3 === 'IND') {
				maxDigit = 10;
			}

			setError(prevState => ({
				...prevState,
				national_id_number: '',
			}));

			let number = e.target.value; // Get the current input value (masked or unmasked)
			const cursorPos = e.target.selectionStart ?? 0; // Get the cursor position (defaults to 0 if undefined)

			if (isMasked) {
				// Handle the logic when SSN is masked (i.e., transforming the input value to reflect masking/unmasking)
				const newOriginalValue = (() => {
					const prevValue = unmaskedSsn; // Previous unmasked SSN value
					const newValue = number; // Current input value (masked)

					const addedChars = newValue.length - prevValue.length; // Calculate the difference in length (added or removed characters)

					if (addedChars > 0) {
						// Adding characters (e.g., user is typing)
						// Extract the newly added characters based on the cursor position
						const addedText = newValue.slice(cursorPos - addedChars, cursorPos);
						return (
							// Rebuild the unmasked value by inserting the newly added characters in the correct position
							prevValue.slice(0, cursorPos - addedChars) +
							addedText +
							prevValue.slice(cursorPos - addedChars)
						);
					} else {
						// Deleting characters (e.g., user is deleting characters)
						const deleteCount = Math.abs(addedChars); // Calculate how many characters were deleted
						return (
							// Rebuild the unmasked value by removing the deleted characters
							prevValue.slice(0, cursorPos) +
							prevValue.slice(cursorPos + deleteCount)
						);
					}
				})();
				number = newOriginalValue; // Update the masked input value to reflect changes in the unmasked SSN
			}
			const validSsnRegex = /^[0-9a-zA-Z/-]*( [0-9a-zA-Z/-]+)*$/;
			const expression =
				/^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
			const digits = number?.replace(/[^0-9a-zA-Z]/g, '');
			const ssnValue = number
				.replace(/[^0-9a-zA-Z/\- ]/g, '')
				.replace(/\s+/g, ' ');

			if (digits.length <= maxDigit + 1 && validSsnRegex.test(number.trim())) {
				if (selectedCountry?.iso3 === 'USA') {
					const isOnlyDigitsRegex = /^[a-zA-Z0-9]+$/;
					const valueToSet = isOnlyDigitsRegex.test(number.trim())
						? number.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3')
						: ssnValue;
					setNationalIdNumber(valueToSet);
				} else {
					setNationalIdNumber(ssnValue);
				}
				setSsnNumber(digits);
				if (isMasked) setUnmaskedSsn(digits); // Update the unmasked SSN state with the new value
				// Render mask effect for the SSN input
				if (digits.length === 0) {
					// If there are no digits, reset the masked SSN to an empty string
					setMaskedSsn('');
				} else {
					// Check if the input number has more characters than the unmasked SSN (indicating the user is typing)
					if (digits.length > unmaskedSsn.length) {
						// Generate the masked SSN dynamically as the user types
						setMaskedSsn(() => {
							const length = digits?.length ?? 0; // Ensure a fallback value for digits length
							const newText = pointGen(length).split(''); // Generate masked format using pointGen and split into an array

							if (number && typeof cursorPos === 'number') {
								// If there's a valid cursor position and the input number exists
								// Safely replace the character at the cursor position with the new character
								newText[cursorPos - 1] = digits[cursorPos - 1] ?? ''; // Default to empty string if character is undefined
							}
							return newText.join(''); // Join the array back into a string and set as masked SSN
						});
					} else {
						// If the user is deleting, simply reset to the generated masked format based on digits length
						setMaskedSsn(pointGen(digits?.length));
					}
				}
			}

			if (!validSsnRegex.test(number.trim())) {
				setError(prevState => ({
					...prevState,
					national_id_number: 'Invalid TaxID Number.',
				}));
				return;
			}

			if (digits.length > maxDigit && validSsnRegex.test(number.trim())) {
				setError(prevState => ({
					...prevState,
					national_id_number: `The maximum length for a TaxID Number should be ${maxDigit} digits.`,
				}));
				return;
			}
			if (
				digits.length < maxDigit &&
				validSsnRegex.test(number.trim()) &&
				/USA|IND/.test(selectedCountry?.iso3)
			) {
				const label = isUsa ? 'SSN' : 'TaxID';
				setError(prevState => ({
					...prevState,
					national_id_number: `The minimum length for a ${label} Number should be ${maxDigit} digits.`,
				}));
				return;
			}
			if (!expression.test(number.trim()) && selectedCountry?.iso3 === 'USA') {
				setError(prevState => ({
					...prevState,
					national_id_number: 'Invalid SSN Format',
				}));
				return;
			}
			if (
				digits.length >= maxDigit &&
				digits.length < maxDigit + 1 &&
				validSsnRegex.test(number.trim())
			) {
				setError(prevState => ({
					...prevState,
					national_id_number: '',
				}));
				return;
			}
		},
		[isMasked, isUsa, selectedCountry?.iso3, setSsnNumber, unmaskedSsn]
	);

	const { onsubmitSSN } = useKycRequests();

	const handleSubmit = useCallback(async () => {
		setIsLoading(true);
		const resp = await onsubmitSSN();
		if (!resp?.message) {
			setCountryListResp([]);
		}
		setIsLoading(false);
	}, [onsubmitSSN, setCountryListResp]);

	// Memoize SSN view icon based on `isMasked`
	const ssnViewIcon = useMemo(
		() => (isMasked ? 'ri-eye-line' : 'ri-eye-off-line'),
		[isMasked]
	);

	// Memoize displayed SSN: masked or original based on `isMasked`
	const displaySSN = useMemo(
		() => (isMasked ? maskedSsn : nationalIdNumber),
		[isMasked, maskedSsn, nationalIdNumber]
	);

	// Toggle SSN mask visibility and update unmasked SSN
	const handleView = useCallback(() => {
		setIsMasked(pre => !pre); // Toggle `isMasked`
		setUnmaskedSsn(ssnNumber); // Set unmasked SSN to original
	}, [ssnNumber]); // Dependency: `ssnNumber` will trigger re-creation

	const bodyContent = useMemo(() => {
		return (
			<div className="input-wrapper-inner">
				<Input
					handleChange={handleChange}
					value={displaySSN}
					placeholder={isUsa ? 'Enter SSN Number' : 'Enter TaxID Number'}
					inputType="text"
					label={isUsa ? 'SSN Number' : 'TaxID Number'}
					isRequired
					errorMessage={error.national_id_number}
					isError={!!error.national_id_number}
					suffixIcon={ssnViewIcon}
					handleSuffixIcon={handleView}
					inputRef={inputRef}
				/>
				<div className="input-wrapper-inner--snn-btn">
					<Button
						type="button__filled button__filled--primary button__large button__block mt-2"
						label="Submit"
						handleClick={handleSubmit}
						disabled={
							!!error.national_id_number || nationalIdNumber.length === 0
						}
					/>
				</div>
			</div>
		);
	}, [
		handleChange,
		nationalIdNumber,
		isUsa,
		error.national_id_number,
		handleSubmit,
		ssnViewIcon,
		displaySSN,
		handleView,
	]);

	const labelElement = useMemo(() => {
		return <LabelElement text="Identity Verification" />;
	}, []);

	const headerElement = useMemo(() => {
		return (
			<div className="provide-ssn__header">
				<div className="provide-ssn__header__title">
					Please provide your TaxID (e.g., SSN, PAN)
				</div>
				<div className="provide-ssn__header__sub-title">
					We need some more information to validate your identity.
				</div>
			</div>
		);
	}, []);

	return isLoading ? (
		<div className="kyc-loader-wrapper">
			<Loader dimension={40} className="loader-blue" />
		</div>
	) : (
		<BodyWrapper
			headerElement={headerElement}
			label={labelElement}
			bodyElement={bodyContent}
		/>
	);
};
