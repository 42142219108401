import { useCallback, useEffect, useState } from 'react';
import { Image } from '@storybook';
import { useVerifyEmail } from '../store';
import { BasicInfoDataState } from 'views/basic-information/store';
import { useRecoilValue } from 'recoil';

import './email-verification.scss';

interface IEmailVerification {
	verificationStatus: string;
}
export const EmailVerification: React.FC<IEmailVerification> = ({
	verificationStatus,
}) => {
	const [counter, setCounter] = useState(60); // Initialize counter to 40 seconds
	const basicInfoData = useRecoilValue(BasicInfoDataState);
	const { sendEmail } = useVerifyEmail();
	const isResendAllowed = counter === 0; // Check if resend is allowed
	const handleResend = useCallback(async () => {
		await sendEmail(basicInfoData?.firstName ?? '', basicInfoData?.email);
		// Logic to resend the email verification
		setCounter(60); // Reset counter to 40 seconds
	}, [basicInfoData, sendEmail, setCounter]);
	useEffect(() => {
		let timer: NodeJS.Timeout | null = null; // Declare timer variable

		if (counter > 0) {
			timer = setInterval(() => {
				setCounter(prev => prev - 1);
			}, 1000);
		}

		// Cleanup function to clear the timer
		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [counter]);

	const verifyRejectContent = useCallback(() => {
		return (
			<div className="verify-modal">
				<div className="verify-modal__image">
					<i className="ri-alert-fill" />
				</div>
				<div className="verify-modal__verify-text-container">
					<div className="verify-modal__title-wrapper">
						<span className="title">Email Verification Denied</span>
						<div className="sub-title">
							<span>
								You have denied the verification of this email address. The
								email has not been verified or linked to your account.
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}, []);

	const verifyApprovedContent = useCallback(() => {
		const formattedSeconds = String(counter).padStart(2, '0');
		return (
			<>
				<div className="EmailVerification">
					<div className="EmailVerification--img">
						<Image fileName="EmailVerificationIcon.svg" />
					</div>
					<section className="EmailVerification--details">
						<h1>Verify your email</h1>
						<div className="EmailVerification--details__text">
							<p>
								A verification email has been sent to{' '}
								<b> {basicInfoData?.email ?? 'N/A'}</b>
							</p>
							<hr className="EmailVerification--details__underline" />
							<p>
								Please check your inbox and spam folder. Kindly click on the
								link provided in the email and complete the verification process
								from there.
							</p>
						</div>

						<div className="verify-modal__footer verify-modal__footer_verify verify-modal--resend-body">
							<>
								{isResendAllowed ? (
									<div className="verify-modal__footer--resend">
										<span className="footer-text">
											Didn’t receive the email?
										</span>
										<span
											className="verify-modal__footer--btn"
											onClick={() => handleResend()}
										>
											Resend
										</span>
									</div>
								) : (
									<div className="verify-modal__footer--box">
										<div className="footer-text">
											<div className="footer-text">
												Didn’t receive the email? Resend in
											</div>
											<div className="verify-modal__footer--time">
												<span className="verify-modal__footer--timer">00</span>
												<span className="verify-modal__footer--colon">:</span>
												<span className="verify-modal__footer--timer">
													{formattedSeconds}
												</span>
											</div>
										</div>
									</div>
								)}
							</>
						</div>
					</section>
				</div>
			</>
		);
	}, [counter, basicInfoData?.email, isResendAllowed, handleResend]);

	return (
		<div className="EmailVerificationContainer">
			{verificationStatus !== 'rejected' ? (
				<>{verifyApprovedContent()}</>
			) : (
				<>{verifyRejectContent()}</>
			)}
		</div>
	);
};
